// @ts-nocheck

import _ from "lodash";

import { SpacesTypes } from "./SPACES.flow-type";

const VALUES = [
  -20,
  -15,
  -10,
  -5,
  0,
  1,
  2,
  3,
  4,
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50
];

const PREFIXES = {
  pad: "padding",
  padTop: "paddingTop",
  padRight: "paddingRight",
  padBot: "paddingBottom",
  padLeft: "paddingLeft",
  padVer: "paddingTop,paddingBottom",
  padHor: "paddingLeft,paddingRight",
  mar: "margin",
  marTop: "marginTop",
  marRight: "marginRight",
  marBot: "marginBottom",
  marLeft: "marginLeft",
  marVer: "marginTop,marginBottom",
  marHor: "marginLeft,marginRight"
};

const BREAKPOINTS = {
  "": obj => obj,
  sm: obj => ({ [`@media (min-width: ${480}px)`]: obj }),
  md: obj => ({ [`@media (min-width: ${720}px)`]: obj }),
  lg: obj => ({ [`@media (min-width: ${960}px)`]: obj }),
  xl: obj => ({ [`@media (min-width: ${1400}px)`]: obj })
};


const generate = (breakpoints = {}, prefixes = {}, values = []) =>
  Object.entries(breakpoints)
    .reduce((acc, [ breakpointName, breakPointCallback ]) => {
      const subValues = Object.entries(prefixes)
        .reduce((acc2, [ propShortHand, cssPropNames ]) => {
          const subValues2 = values.map(val => {
            const keyName = _.camelCase([
              breakpointName,
              propShortHand,
              (val < 0 ? "Minus" : ""),
              val
            ]);
            const subValues3 = cssPropNames.split(",")
              .map(propName => ({
                [propName]: `${val}px !important`
              }));
            const obj = {
              [keyName]: breakPointCallback(Object.assign({}, ...subValues3))
            };
            return obj;
          });
          return Object.assign({}, acc2, ...subValues2);
        }, {});

      return Object.assign({}, acc, subValues);

    }, {});


// console.time("css-spaces-generation");
const SPACES: SpacesTypes = generate(BREAKPOINTS, PREFIXES, VALUES);
// console.timeEnd("css-spaces-generation");


// const SPACES2 = {};
// console.time("css-spaces-generation old");
// /* eslint-disable-next-line */
// _.forOwn(BREAKPOINTS, (mediaCb, bp) => {
//   _.forEach(_.keys(PREFIXES), prefix => {
//     _.forEach(VALUES, val => {
//       const prefixProps = _.split(PREFIXES[prefix], ",");
//       const keyName = _.camelCase(`${bp}-${prefix}-${val < 0 ? "Minus" : ""}${val}`);
//       SPACES2[_.camelCase(keyName)] = mediaCb(Object.assign({}, ..._.map(prefixProps, item => ({
//         [item]: val
//       })))
//       );
//     });
//   });
// });
// console.timeEnd("css-spaces-generation old");


export default SPACES;
