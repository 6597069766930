const COLORS = {
  primary: "#0099ff",
  secondary: "#2B389D",

  success: "#37CC5F",
  error: "#CC5658",
  warning: "#e67e22",
  info: "#4251CD",

  //
  // ─── FROM ZEPLIN ────────────────────────────────────────────────────────────────
  //
  whiteLilac: "#F6F7FB",
  dark: "#0e1720",
  paleGrey: "#f6f7fb",
  paleGreyTwo: "#f7f7f9",
  paleGreyThree: "#e9ebf1",
  paleGreyFour: "#dcdee5",
  battleshipGrey: "rgb(110,115,129)",
  blueyGrey: "rgb(172,177,192)",
  lightRoyalBlue: "#003367",
  marineBlue: "#003367",
  salmon: "#ff7c7c",
  brightTeal: "#06edc1",
  charcoalGrey: "#2d353d",
  coolGrey: "#8e9295",
  azure: "#0099ff",
  greenishTeal: "#36cd77",
  slateGrey: "#63686e",
  lighterGrey: "#e3e3e3",
  orangeYellow: "#ffa300",
  coral: "#fc4840",
  greyishBrown: "#555555",
  darkBlueGrey: "#223d58",
  darkTwo: "#111a23",
  silver: "#b9bbbd",
  darkSlateBlue: "#284867",
  //
  // ─── ALL ────────────────────────────────────────────────────────────────────────
  //
  alizarin: "#e74c3c",
  amethyst: "#9b59b6",
  asbestos: "#7f8c8d",
  belizeHole: "#2980b9",
  blueArmy: "#4251CD",
  blueLight: "#2350A4",
  blueNavy: "#232E85",
  blueOcean: "#00BAFF",
  blueOil: "#2B389D",
  butturcup: "#f6a623",
  carrot: "#e67e22",
  clouds: "#ecf0f1",
  concrete: "#95a5a6",
  emerald: "#2ecc71",
  gray: "#627990",
  green: "#1CB95F",
  greenSea: "#16a085",
  lightGray: "#8AA3BC",
  lync: "#62798f",
  midnightBlue: "#2c3e50",
  nephritis: "#27ae60",
  orange: "#F25F2B",
  peterRiver: "#3498db",
  pomegranate: "#c0392b",
  pumpkin: "#d35400",
  red: "#FA5454",
  rock: "#a3b9d0",
  sapphire: "#28499f",
  smalt: "#597991",
  sunFlower: "#f1c40f",
  turquoise: "#1abc9c",
  wetAsphalt: "#34495e",
  white: "#FDFDFD",
  wisteria: "#8e44ad",
  primaryOrange: "#FF6600",
  yellow: "#ebc934",

  //
  // ─── BRANDS ─────────────────────────────────────────────────────────────────────
  //
  blogger: "#f57d00",
  codepen: "#a561cb",
  dropbox: "#007ee5",
  evernote: "#7ac142",
  facebook: "#3b5998",
  flickr2: "#ff0084",
  flickr: "#0063dc",
  foursquare: "#0072b1",
  github2: "#999999",
  github: "#4183c4",
  goodreads: "#9F955B",
  google2: "#db4437",
  google: "#4285f4",
  googlePlus: "#dd4b39",
  imdb: "#f5de50",
  instagram: "#3f729b",
  lastfm: "#c3000d",
  linkedin: "#0976b4",
  pinterest: "#cc2127",
  rss: "#f26522",
  spotify: "#7ab800",
  swarm: "#F8741F",
  tumblr: "#35465c",
  twitter: "#55acee",
  vimeo2: "#1ab7ea",
  vimeo: "#162221",
  vine: "#00b488",
  whatsapp: "#25D366",
  youtube2: "#b31217",
  youtube: "#e52d27"

};

export default COLORS;
