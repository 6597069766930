// @ts-nocheck

import BREAKPOINTS from "./BREAKPOINTS";


const HELPERS = {

  media: {
    extraNarrow: "@media (max-width: 330px)",
    smallerThan: {
      sm: `@media (max-width: ${BREAKPOINTS.sm - 1}px)`,
      md: `@media (max-width: ${BREAKPOINTS.md - 1}px)`,
      lg: `@media (max-width: ${BREAKPOINTS.lg - 1}px)`,
      xl: `@media (max-width: ${BREAKPOINTS.xl - 1}px)`,
      custom: val => `@media (max-width: ${val}px)`
    },
    largerThan: {
      sm: `@media (min-width: ${BREAKPOINTS.sm}px)`,
      md: `@media (min-width: ${BREAKPOINTS.md}px)`,
      lg: `@media (min-width: ${BREAKPOINTS.lg}px)`,
      xl: `@media (min-width: ${BREAKPOINTS.xl}px)`,
      custom: val => `@media (min-width: ${val}px)`
    }
  },

  color: {
    lightenDarken: (color: string = "#ffffff", amount: number = 0) => {
      try {
        let usePound = false;
        if (color[0] === "#") {
          color = color.slice(1);
          usePound = true;
        }
        const num = parseInt(color, 16);
        let r = (num >> 16) + amount;
        if (r > 255) r = 255;
        else if (r < 0) r = 0;
        let b = ((num >> 8) & 0x00FF) + amount;
        if (b > 255) b = 255;
        else if (b < 0) b = 0;
        let g = (num & 0x0000FF) + amount;
        if (g > 255) g = 255;
        else if (g < 0) g = 0;
        return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
      }
      catch (err) {
        window["console"]["log"]("error", "HELPERS", err); // eslint-disable-line dot-notation
        return color;
      }
    },
    HexToRgba: (color: string, opacity: number = 1) => {
      try {
        let c;
        c = color.substring(1).split("");
        if (c.length === 3) {
          c = [ c[0], c[0], c[1], c[1], c[2], c[2] ];
        }
        c = `0x${c.join("")}`;
        return `rgba(${[
          (c >> 16) & 255,
          (c >> 8) & 255,
          c & 255
        ]
          .join(",")
        }, ${opacity})`;
      }
      catch (err) {
        window["console"]["log"]("error", "HELPERS", err); // eslint-disable-line dot-notation
        return color;
      }
    }
  }

};


export default HELPERS;
