// @ts-nocheck

import _ from "lodash";

import { FlexTypes } from "./FLEX.flow-type";


const CSS_VALUES = {
  "display": [
    "block",
    "inline-block",
    "inline",
    "inline-flex",
    "flex"
  ],
  "flex-direction": [
    "row",
    "column",
    "row-reverse",
    "column-reverse"
  ],
  "flex-wrap": [
    "wrap",
    "nowrap",
    "wrap-reverse"
  ],
  "justify-content": [
    "flex-start",
    "flex-end",
    "center",
    "space-between",
    "space-around"
  ],
  "align-items": [
    "flex-start",
    "flex-end",
    "center",
    "baseline",
    "stretch"
  ],
  "align-content": [
    "flex-start",
    "flex-end",
    "center",
    "space-between",
    "space-around",
    "stretch"
  ],
  "align-self": [
    "auto",
    "flex-start",
    "flex-end",
    "center",
    "baseline",
    "stretch"
  ]
};


const BREAKPOINTS = {
  "": obj => obj,
  sm: obj => ({ [`@media (min-width: ${480}px)`]: obj }),
  md: obj => ({ [`@media (min-width: ${720}px)`]: obj }),
  lg: obj => ({ [`@media (min-width: ${960}px)`]: obj }),
  xl: obj => ({ [`@media (min-width: ${1400}px)`]: obj })
};



const generate = (cssValues = {}, breakpoints = {}) =>
  Object.entries(cssValues)
    .reduce((acc = {}, [ cssProp, cssValuesArray ]) => {
      return Object.assign({}, acc,
        Object.entries(breakpoints)
          .reduce((acc2, [ breakpointName, breakPointCallback ]) => {
            const subValues = cssValuesArray.map((cssValue => {
              const keyName = _.camelCase([
                breakpointName,
                cssProp.replace(/flex-/, ""),
                cssValue
              ]);
              return {
                [keyName]: breakPointCallback({
                  [_.camelCase(cssProp)]: `${cssValue} !important`
                })
              };
            }));
            return Object.assign({}, acc2, ...subValues);
          }, acc)
      );

    }, {});



// console.time("css-flex-generation");
const FLEX: FlexTypes = generate(CSS_VALUES, BREAKPOINTS);
// console.timeEnd("css-flex-generation");

// console.log(JSON.stringify(FLEX, null, 2, "  "));


export default FLEX;
