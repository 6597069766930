const BREAKPOINTS = {
  xs: 0,
  sm: 700,
  md: 900,
  lg: 1280,
  xl: 1600,
  mobileMaxFluidWidth: 620
};


export default BREAKPOINTS;
