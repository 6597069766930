import { css, keyframes } from "@emotion/react";

import COLORS from "./COLORS";



const animationKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;


const STYLES = {

  visuallyHidden: css`
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
  `,


  dividerWithText: css`
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    padding: 0 10px;
  }
  &:after,
  &:before {
    content: "";
    flex: 1 1 auto;
    display: inline-block;
    height: 1px;
    background-color: ${COLORS.paleGrey}
  }
  `,


  loading: css`
    position: relative;
    overflow: hidden;
    z-index: 1;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: rgba(255,255,255,0.9);
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 40px;
      height: 40px;
      background-position: center center;
      z-index: 3;
      animation: ${animationKeyframes} 1000ms linear infinite;
      opacity: 0.3;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' x='0px' y='0px' viewBox='0 0 508.5 508.5' xmlSpace='preserve'%3E%3Cg%3E%3Cpath d='M370.9,196.7l68.8,68.8l68.8-68.8L485.9,174l-30,30c-11.4-46.2-38.4-87-76.3-115.7C288,19.1,157.7,37.2,88.5,128.7L114,148 c22.4-29.5,53.4-51.2,88.9-62c92.9-28.4,191.2,24,219.5,116.9L393.6,174L370.9,196.7z' /%3E%3Cpath d='M0,311.5l22.7,22.6l30-29.9c22.8,92.8,105.9,158,201.4,158c11.3,0,22.6-0.9,33.8-2.7c54.5-8.8,103.2-39,135.2-83.8 l-26-18.7c-22.6,31.4-54.9,54.4-91.9,65.6C212.2,450.7,113.9,398.1,85.8,305l29.1,29.1l22.6-22.6l-68.8-68.8L0,311.5z M67.6,289.2 l1.2-1.2l0.8,0.8L67.6,289.2z' /%3E%3C/g%3E%3C/svg%3E");
    }
  `,
  dimmed: css`
    position: relative;
    overflow: hidden;
    z-index: 1;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: rgba(255,255,255,0.7);
    }
  `



};



export default STYLES;
