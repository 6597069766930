// @ts-nocheck

import _ from "lodash";

import { MinHeightTypes } from "./MIN_HEIGHT.flow-type";


const VALUES = [
  10,
  20,
  30,
  40,
  50,
  60,
  70,
  80,
  90,
  100,
  150,
  200,
  250,
  300,
  350,
  400,
  450,
  500
];


const generate = (values = []) =>
  values
    .reduce((acc = {}, val) =>
      Object.assign({}, acc, {
        [`minHeight${val}`]: {
          minHeight: `${val}px !important;`
        }
      })
    );



// console.time("css-min-height-generation");
const MIN_HEIGHT: MinHeightTypes = generate(VALUES);
// console.timeEnd("css-min-height-generation");

// console.log(JSON.stringify(Object.keys(MIN_HEIGHT), null, 2, "  "));


export default MIN_HEIGHT;
